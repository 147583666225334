import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { respondTo } from '../../../../theme/mixin.js';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import Fade from '../../../Fade/Fade';
import styled from 'styled-components/macro';
import bggame from '../../../../assets/images/bggame.jpg';
import { ReactComponent as HandOpen } from '../../../../assets/images/handOpen.svg';
import iconParticipe from '../../../../assets/images/boxGame.png';
import { Box, Button, Col, Flex, Loader, Modal, Text } from '../../../Ui';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import customAxios from '../../../../config/axios-refresh-token.js';
import { getUserInfo } from '../../../../store/actions/user.js';
import BoxLayout from '../../../Ui/BoxLayout/index.js';
import { Link } from 'react-router-dom';
import { acceptGameRules } from '../../../../store/actions/game.js';
import { getGame } from '../../../../store/actions/promo.js';
import NotFound from '../../../../views/NotFound/NotFound.js';

const Container = styled.div`
  height: 530px;

  .title-and-content-wrapper {
    position: relative;
    z-index: 1;
    padding: 20px 16px;

    span:nth-child(1) {
      padding-bottom: 15px;
    }
  }

  .content-participe {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .overlay {
    position: absolute;
    margin-top: 44px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff99;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    padding: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;

    ${respondTo.sm` 
      margin-top: 70px;
      padding: 20px;
      width: 100px;
      height: 100px;
    `};
  }
`;

const TextParent = styled.div`
  text-align: center;
`;
const TextContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  ${respondTo.sm`
	font-size: 22px;
	margin-left: 10px;
  `}
`;

const WrapperGame = styled.div`
  max-width: 960px;
  margin: 36px auto;
  max-width: 96%;
  text-align: center;
  ${respondTo.med`
		max-width: 90%;
	`}
  ${respondTo.sm`
		max-width: 760px;
	`}
	${respondTo.md`
		max-width: 830px;
	`}
`;
const OverlayRegola = styled.div``;

const Click = props => {
  const {
    onShowResult,
    lang,
    typeInteraction,
    g: {
      played,
      id,
      interaction,
      preinteraction,
      gamePrivacyAccepted,
      already_won,
      stop_attempts_after_win,
      log: { sid },
      settings: { option },
      i18l: {
        info: { title, content },
      },
    },
    action: { data: dataAction, loading },
    regolamento,
    privacy,
    remaining_attempts,
  } = props;

  const dispatch = useDispatch();
  const { games } = useSelector(state => state.game || {});
  const extra = useSelector(state => state.user.user.userinfo?.extra || null);
  const [isregula, setIsregula] = useState(false);
  const [action, setAction] = useState(false);
  const [regulationChecked, setRegulationChecked] = useState(false);
  const [attemptsError, setAttemptsError] = useState(false);

  let slug = getSlug();

  useEffect(() => {
    if (extra && games.length > 0) {
      const newExtra = JSON.parse(extra);
      if (newExtra.accepted_privacy.find(id => id === games[0]?.id)) {
        setIsregula(false);
      } else {
        setIsregula(true);
      }
    }
  }, [extra, games]);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  const {
    settings: {
      aspect: { pregame },
    },
    i18l: {
      it: { textUp, buttonLabel },
    },
  } = rules;

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  const clickFinalHandler = index => {
    setAction(true);
  };

  useEffect(() => {
    if (regulationChecked) {
      dispatch(acceptGameRules(id, slug));
    }
  }, [regulationChecked]);

  const toggleRegulation = () => {
    setRegulationChecked(!regulationChecked);
  };

  return (
    <Container>
      <React.Fragment>
        {loading || action ? (
          <Loader show={loading} />
        ) : (
          <>
            {gamePrivacyAccepted ? (
              <>
                <div className='title-and-content-wrapper'>
                  <Text white size={24}>
                    {title}
                  </Text>
                  <Text white size={16} bold className='title-participe'>
                    {content}
                  </Text>
                </div>
                <div className='content-participe'>
                  <img src={pregame} alt='' />
                  <div
                    className='overlay'
                    onClick={
                      stop_attempts_after_win && already_won
                        ? () => setAttemptsError(true)
                        : () => clickFinalHandler()
                    }
                  >
                    <HandOpen />
                  </div>
                </div>
              </>
            ) : (
              <>
                <OverlayRegola className='overlay_regola'>
                  <div className='background-overlay'>
                    <div className='title-and-content-wrapper'>
                      <Text white size={24}>
                        {title}
                      </Text>
                      <Text white size={16} bold className='title-participe'>
                        {content}
                      </Text>
                    </div>
                    <img src={pregame} alt='' />
                  </div>

                  <div className='accept-wrapper'>
                    <Text bold className='text-regola'>
                      Accetta il Regolamento e prendi
                      <br />
                      visione dell’
                      <a href={privacy || ''} target='_blank' rel='noopener noreferrer'>
                        Informativa Privacy
                      </a>
                      <br />
                      per partecipare al concorso
                    </Text>
                    <Flex className='regola' align='center'>
                      <a href={regolamento} target='_blank' rel='noopener noreferrer'>
                        Regolamento
                      </a>

                      <Switch
                        checked={regulationChecked}
                        onChange={toggleRegulation}
                        onColor='#86d3ff'
                        onHandleColor='#2693e6'
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                        height={20}
                        width={48}
                        className='react-switch'
                        id='material-switch'
                      />
                    </Flex>
                  </div>
                </OverlayRegola>
              </>
            )}
          </>
        )}
        {action ? (
          <Dynamic
            id={id}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            sid={sid}
            {...props}
          />
        ) : null}
      </React.Fragment>
      <Modal
        show={attemptsError}
        close={() => {
          setAttemptsError(false);
        }}
      >
        {already_won ? (
          <p>Hai già vinto un premio per questo gioco.</p>
        ) : (
          <p>Per oggi hai terminato i tentativi di gioco.</p>
        )}
      </Modal>
    </Container>
  );
};

Click.propTypes = {};

export default Click;
