import React, { useState, useEffect } from 'react';
import useForm from '../../../hooks/useForm';
import { Input, Button, Wrapper, Loader, Text, Modal, Col, Box, Flex } from '../../Ui';
import Fade from '../../Fade';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import customaxios from '../../../config/axios-refresh-token';
import { getSlug } from '../../../utils/utils';
import { connect, useDispatch, useSelector } from 'react-redux';
import InputField from '../../Ui/Input/InputField/InputField';
import * as actionCreators from '../../../store/actions';
import moment from 'moment';
import parse from 'html-react-parser';
const Response = styled.div`
  text-align: center;
`;

const Extra = styled.p`
  font-size: 20px;
`;

const Form = props => {
  const {
    form: {
      id: formId,
      option: data,
      type,
      notes,

      // i18l: { title, content, extra },
    },
    prize,
    game: gameName,
    promotion: { slug, pdf_privacy },
    lang,
    id_game: id,
    playslog_id: playslog,
    //formResponse,
    // played,
    checkFormCompiled,
    checkFormPrizeAccepted,
    //buttonAlign,
    //userInfo,
    //game_cms,
    //formWin,
  } = props;

  let showResponse = false;
  /*   if (formResponse !== null && played) {
    showResponse = true;
  } */

  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState('');
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const dataForm = JSON.parse(data);
  //const { form, id_game, playslog_id, promotion, game: gameName, prize } = formInfo || {};

  //let slug = getSlug();

  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const submitHandler = e => {
    const objData = {};
    for (const property in formData) {
      objData[property] = formData[property].value;
    }

    const data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === 'fileextra') {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append('formId', formId);
    data.append('data', JSON.stringify(objData));
    data.append('section', 'form');
    if (type === 'post') {
      data.append('playslog', playslog);
    }
    setLoading(true);
    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data.data.status) {
            setShowFormResponse(true);
          } else {
            setShowModal(true);
            setInfo(response.data.data.info);
          }
          if (
            response.data.data &&
            JSON.parse(response.data.data.data) &&
            JSON.parse(response.data.data.data).prize
          ) {
            checkFormPrizeAccepted(JSON.parse(response.data.data.data).prize);
          }
          checkFormCompiled(formId);
          // dispatch(actionCreators.getUserInfo());
        }
      })
      .catch(error => {
        //console.log(error.response.data.error);
        setLoading(false);
        setShowModal(true);
        setInfo(error.response.data.error);
      });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    onSetFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={inp.value}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        setFormValid={onSetFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        provinces={provinces}
        {...inp}
      />
    );
  });

  return (
    <Wrapper>
      <Container>
        {showFormResponse ? (
          <Fade show={true}>
            <Response>
              <Text primary size={34} align='center' className='mb-20'>
                Grazie! La tua risposta è stata salvata correttamente.
              </Text>

              <Link to='/'>
                <Button active hover style={{ display: 'block', margin: '20px auto' }}>
                  Torna alla homepage
                </Button>
              </Link>
            </Response>
          </Fade>
        ) : (
          <div className='formBox'>
            <Box style={{ paddingBottom: 0 }}>
              <Text size={24} primary bold className='mb-10'>
                {prize + ' - ' + gameName}
              </Text>
              {notes && <Text className='mb-20'>{notes}</Text>}
              {inputs.find(item => item.key === 'prize')}

              {inputs.find(item => item.key === 'prize')?.props.value === '1' && (
                <>
                  {inputs.filter(item => item.key !== 'prize')}
                  <small>
                    Ti ricordiamo che i tuoi dati personali saranno trattati ai sensi di quanto
                    previsto nell’
                    <a href={pdf_privacy || ''} target='_blank' rel='noopener noreferrer'>
                      informativa privacy
                    </a>{' '}
                    del concorso.
                  </small>
                </>
              )}
            </Box>

            <Button
              loading={loading}
              upper
              active
              center
              bold
              hover
              white
              style={{ marginTop: '15px', display: 'block', textAlign: 'center' }}
              onClick={formSubmitHandler}
              disabled={loading}
            >
              Invia
            </Button>
          </div>
        )}
      </Container>
      <Modal show={showModal} close={closeModal}>
        <p>{info && parse(info)}</p>
      </Modal>
    </Wrapper>
  );
};
const Container = styled.div`
  margin-top: 120px;
  margin-bottom: 30px;
  .formBox {
    width: 100%;
    margin: 0 auto;
    ${respondTo.sm` 
    width:66%;
  `};

    small {
      font-size: 10px;
      display: block;
      margin-top: 8px;
      margin-bottom: 20px;
    }
  }
`;
const CustomBox = styled(Box)`
  width: 95%;
  margin: 0 auto;
  ${respondTo.sm` 
    width:60%;
  `};
`;
const PersonalDataBox = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  strong {
    font-size: 12px;
    color: ${props => props.theme.primary};
    text-transform: uppercase;
  }
  .content {
    //background-color: ${props => props.theme.primary};
    padding: 15px;
    margin: 0 -15px;
  }
`;

const mapStateToProps = state => {
  return {
    getCheckFormCompiled: state.game.checkFormCompiled,
    userInfo: state.user.user.userinfo,
    game_cms: state.cms.game,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    checkFormPrizeAccepted: prize => dispatch(actionCreators.checkFormPrizeAccepted(prize)),
    checkFormCompiled: formId => dispatch(actionCreators.checkFormCompiled(formId)),
  };
};

// export default Form;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
