import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Prize from '../Prize';
import Form from '../../Form';
import ResultDynamic from '../ResultDynamic';
import { Button, Col, Box, Flex, Text } from '../../../Ui';
import Fade from '../../../Fade/Fade';
import { getElement } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import LeftBoxCardHandler from '../../Handlers/CardHandler/LeftBoxCardHandler';
import emptyBox from '../../../../assets/images/boxLoose.png';
import ThankYouPage from '../../Handlers/ThankYouPage/ThankYouPage';

const ResultGame = props => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      check: { attemptslimit },
      log: { steps, won, code, error, form: formResponse, playslog: playslog_id, formend },
      i18l,
      dynamic: { type },
      form,
    },
    lang,
    played,
    action,

    action: { cleanData },
    click,
  } = props;

  const [isFormOpen, setIsFormOpen] = useState(false);
  useEffect(() => {
    // window.scrollTo(0, 0);
    return () => {
      cleanData(id);
    };
  }, []);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};
  let correctResponse = action.correctAnsw;
  let errorNoprize = action.errorNoprize;
  let correct_answ = null;
  if (played) {
    theData = steps.interaction.data;
  } else {
    correct_answ = correctResponse;

    theData = theProfileData.data ? theProfileData.data : null;
  }

  let rulesData = JSON.parse(rules);
  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
    };
  } else {
    theCodeData = { code: code, error: error };
  }

  let playslog = theProfileData ? theProfileData.playslog : log.playslog;
  let userWon = theProfileData ? theProfileData.won : won;
  let thePrize = theProfileData ? theProfileData.prize : log.prize;

  const {
    settings: {
      aspect: { brand, loosegame, wingame },
    },
  } = rulesData;

  let resultDynamic = null;
  if (attemptslimit && type === 'noprize') {
    resultDynamic = 'info';
  } else if (userWon) {
    resultDynamic = 'win';
  } else {
    resultDynamic = 'loose';
  }

  let dynamicData = i18l[resultDynamic];
  const checkFormCompiled = useSelector(state => state.game.formCompiled);
  const checkFormPrizeAccepted = useSelector(state => state.game.formCompiledPrizeAccepted);
  return (
    <Container click={click}>
      <div className='content'>
        {type === 'noprize' ? (
          <ThankYouPage correct_answ={correct_answ} errorNoprize={errorNoprize} {...props} />
        ) : won || theProfileData?.won ? (
          <ResultDynamic
            dynamicData={dynamicData}
            thePrize={thePrize || {}}
            wingame={wingame}
            checkFormCompiled={checkFormCompiled}
            checkFormPrizeAccepted={checkFormPrizeAccepted}
            playslog_id={action?.data[0]?.playslog || playslog_id}
            id_game={id}
            click
            form={form}
            formend={formend}
            attemptslimit={attemptslimit}
          />
        ) : (
          <div className='loose'>
            <div className='content-wrapper'>
              <Text loose size={24} bold>
                {i18l?.loose?.title}
              </Text>
              <Text size={16} white>
                {i18l?.loose?.content}
              </Text>
            </div>
            <img src={click ? loosegame : emptyBox} alt='' />
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;

  .content {
    width: 100%;
    position: relative;
    height: 100%;

    .loose {
      height: 530px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: ${({ click }) => (click ? 'flex-start' : 'center')};

      .content-wrapper {
        padding: 20px 16px;
        z-index: 1;

        span:nth-child(1) {
          padding-bottom: 15px;
        }
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .content-cta {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 40px;
    }
  }
`;

export default ResultGame;
