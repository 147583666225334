import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../../Ui';
import iconParticipe from '../../../../assets/images/boxWin.png';

import { useDispatch } from 'react-redux';
import { getFormInfo } from '../../../../store/actions/user';
import { useHistory } from 'react-router';
import { ChevronRight } from '@styled-icons/boxicons-regular';
import { respondTo } from '../../../../theme/mixin';

const ResultDynamicWrapper = styled.div`
  position: relative;
  text-align: ${({ click }) => (click ? 'left' : 'center')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ click }) => (click ? 'stretch' : 'center')};
  height: 530px;

  .content-wrapper {
    z-index: 1;
    padding: 20px 16px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .btnDesk,
  .btnMob {
    width: fit-content;
  }

  .btnDesk {
    display: none;
    ${respondTo.sm` 
        display: block;
    `};
  }
  .btnMob {
    display: block;
    ${respondTo.sm` 
        display: none;
    `};
  }
`;

const ResultDynamic = props => {
  const {
    dynamicData: { title = '', content },
    thePrize: { title: titleWin = '', content: contentWin, extra },
    wingame,
    click,
    playslog_id,
    id_game,
    form,
    //extra,
    formend,
    attemptslimit,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const clickHandler = () => {
    if (form) {
      if (formend) return;
      dispatch(getFormInfo(id_game, playslog_id)).then(() => {
        history.push('/form-win');
      });
    }
  };
  return (
    <ResultDynamicWrapper click={click}>
      <div className='content-wrapper'>
        <Text white size={28} bold>
          {title + ' ' + titleWin}
        </Text>
        {!formend && (
          <Button
            active
            upper
            hover
            onClick={clickHandler}
            className='btnMob'
            style={{ marginTop: 15 }}
          >
            Richiedi subito
          </Button>
        )}
      </div>

      <img src={click ? wingame : iconParticipe} alt='' />

      <div className='content-wrapper'>
        <Text white size={28} bold>
          {extra || content}
        </Text>

        {!formend && (
          <Button
            active
            upper
            hover
            onClick={clickHandler}
            className='btnDesk'
            style={{ marginTop: 15 }}
          >
            Richiedi subito
          </Button>
        )}
      </div>
    </ResultDynamicWrapper>
  );
};

export default ResultDynamic;
