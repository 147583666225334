import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../components/Games/Form';
import { getUserInfo } from '../../store/actions';
import { Box, Text, Wrapper } from '../../components/Ui';

function FormWin() {
  const dispatch = useDispatch();
  const formInfo = useSelector(state => state.user.formInfo || {});
  const lang = useSelector(state => state.app.config?.lang);

  useEffect(() => {
    return () => {
      dispatch(getUserInfo());
    };
  }, []);

  return formInfo?.form ? (
    <Wrapper>
      <Form lang={lang} {...formInfo} />
    </Wrapper>
  ) : (
    <Wrapper>
      <Box style={{ marginTop: '115px' }}>
        <Text> Il form non esiste</Text>
      </Box>
    </Wrapper>
  );
}

export default FormWin;
