import React, { useEffect } from 'react';
import { Box, Button, Col, Flex, Loader, Text, Wrapper } from '../../components/Ui';
import Seo from '../../components/Seo';
import Games from '../../components/Games';
import { getSlug } from '../../utils/utils';
import { Link, useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as actionCreators from '../../store/actions';
import BoxLayout from '../../components/Ui/BoxLayout';
import ContentSlide from '../../components/ContentSlide';
import Banner from '../../components/banner';
import CustomSlider from '../../components/customSlider';
import Fade from '../../components/Fade';
import { respondTo } from '../../theme/mixin';
import img from '../../assets/images/banner-placeholder.svg';
import NotFound from '../NotFound';
import { useState } from 'react';
var options = {
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'custom-slider',
  infinite: true,
  dots: true,
  arrows: false,
  adaptiveHeight: true,
  dotsClass: 'slider-dots',
  autoplay: true,
};
const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .customFile-img,
  .customFile-info {
    width: 48%;
    flex-grow: 1;
  }
  .customFile-info {
    padding-top: 5px;
  }
  .label-contact {
    color: #0a89cb;
    font-size: 10px;
  }
  .select-contact {
    option {
      color: #002d72;
    }
    & option:first-child {
      color: #47545d59;
    }
    &:invalid,
    & option[value=''] {
      color: #47545d59;
    }
  }
  .qui {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const SecondSlid = styled.div``;
const HiwBanner = styled.div`
  .slider-dots {
    right: 25px;
  }
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: top;
    `};
  }
  .hiw-text {
    ${respondTo.sm` 
      margin-right:0;
      width:35.5%;
      right:auto;
      left:35px;
    `};
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  //margin-top: 60px;
`;

const Game = props => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    lang,
    isLogged,
    getGame,
    game,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    errorSendGameResult,
    loadingGetCodeAfterResult,
    loadinggame,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
    sunnyFriday,
  } = props;

  let sendGameObj = {
    getGame,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    loadingGetCodeAfterResult,
    errorSendGameResult,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
  };

  //let slug = 'sunny-friday';
  let { id, slug } = useParams();
  const promo = useSelector(state => state.promo?.promoinfo);
  const error404 = useSelector(state => state.promo?.error404);
  const error1010 = useSelector(state => state.promo?.error1010);
  const idUser = useSelector(state => state.user?.user?.userinfo?.id);
  const { option } = useSelector(state => state.promo.game?.[0]?.settings || {});

  const dispatch = useDispatch();
  const { img_desktop, img_mobile, pdf_regolamento, pdf_privacy, i18l, img_box, dark_text, gallery } =
    promo || {};

  const history = useHistory();
  const [allowedToPlay, setAllowedToPlay] = useState(false);
  useEffect(() => {
    if (Object.keys(promo).length === 0 && idUser) {
      dispatch(actionCreators.getPromoInfo(slug));
    }
  }, [promo, idUser]);

  useEffect(() => {
    if (id && idUser) {
      getGame(id, slug, props.history.location.state ? props.history.location.state.cid : '');
    }
  }, [id, idUser]);

  useEffect(() => {
    const days = option ? JSON.parse(option)?.play_limit_days : [];
    if (days?.length === 0 || days?.includes(new Date().getDay())) {
      setAllowedToPlay(false);
    } else {
      setAllowedToPlay(true);
    }
  }, [option]);

  let seo = null;

  if (game?.[0]?.i18l?.seo) {
    if (Object.entries(game?.[0]?.i18l?.seo).length !== 0) {
      const {
        seo: { title, description, keywords },
      } = game[0]?.i18l || {};
      seo = <Seo title={title} description={description} keywords={keywords} />;
    }
  }

  if (loadinggame) {
    return <Loader show={loadinggame} style={{ marginTop: -60 }} />;
  }
  return error404.length > 0 || allowedToPlay || error1010.length > 0 ? (
    <NotFound type='promo' error={404} />
  ) : (
    <>
      {/*    {!sunnyFriday ? ( */}
      {seo}
      <Wrapper>
        <Banner>
          <HiwBanner>
            <CustomSlider settings={options}>
              <SecondSlid>
                <ContentSlide
                  title={i18l?.title}
                  text={i18l?.subtitle}
                  img={img_desktop}
                  imgMobile={img_mobile}
                  noBtn
                  darkText={dark_text}
                  textBold
                />
              </SecondSlid>
              {gallery?.length > 0 &&
                gallery?.map(item => (
                  <SecondSlid>
                    <ContentSlide
                      title={item.title}
                      text={item.text}
                      img={item.img_desktop}
                      imgMobile={item.img_mobile}
                      noBtn
                      textBold
                      darkText={item.dark_text}
                    />
                  </SecondSlid>
                ))}
            </CustomSlider>
          </HiwBanner>
        </Banner>
        <CustomFlex align='stretch' justify='center'>
          <Col padding={10} width={60} style={{ flexGrow: 1 }}>
            {/*  <Box style={{ height: '100%' }}> */}
            <Container>
              <Games
                games={game}
                isLogged={isLogged}
                sendGameObj={sendGameObj}
                lang={lang}
                regolamento={pdf_regolamento}
                privacy={pdf_privacy}
              />
            </Container>
            {/*  </Box> */}
          </Col>
        </CustomFlex>
      </Wrapper>
      {/*   ) : (
        <Container>
          {loadinggame ? (
            <Loader show={loadinggame} style={{ marginTop: -60 }} />
          ) : (
            <Games games={game} isLogged={isLogged} sendGameObj={sendGameObj} lang={lang} />
          )}
        </Container>
      )} */}
    </>
  );
};

Game.propTypes = {};

const mapStateToProps = state => {
  return {
    lang: state.app.config.lang,
    game: state.promo.game,
    loadinggame: state.promo.loadinggame,
    loadingSendGameResult: state.game.loadingSendGameResult,
    errorSendGameResult: state.game.errorSendGameResult,
    loadingGetCodeAfterGame: state.game.loadingGetCodeAfterGame,
    errorGetCodeAfterGame: state.game.errorGetCodeAfterGame,
    loadingGetCodeAfterResult: state.game.loadingGetCodeAfterResult,
    gameRetry: state.promo.gameRetry,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGame: (id, slug, cid) => dispatch(actionCreators.getGame(id, slug, cid)),
    sendGameResult: (id, result) => dispatch(actionCreators.sendGameResult(id, result)),
    getCodeAfterGame: (id, result, gc) => dispatch(actionCreators.getCodeAfterGame(id, result, gc)),
    setGameRetry: () => dispatch(actionCreators.setGameRetry()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game));
